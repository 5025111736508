import { useRouter } from 'next/router'

import { isClient } from '@/utils/plain/isClient'

export const useBaseUrlWithLocale = () => {
  const router = useRouter()

  if (isClient) {
    return `${window.location.origin}/${router.locale}`
  }

  return ''
}

export const useCurrentUrlWithoutHashtag = () => {
  const baseUrlWithLocale = useBaseUrlWithLocale()
  const router = useRouter()

  if (baseUrlWithLocale) {
    const url = new URL(baseUrlWithLocale + router.asPath)
    return `${url.origin}${url.pathname}`
  }

  return ''
}
