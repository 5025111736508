import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'

import { keyBy } from 'lodash'
import { useDeepCompareMemo } from 'use-deep-compare'

import type { Category } from '@/types'
import { isNotEmpty } from '@/utils/plain/isEmpty'

export const ALL_PRODUCTS_CATEGORY_ID = 'all-products'

export type AllProductsCategory = Pick<Category, 'id' | 'order' | 'name'>

export const allProductsCategoryInitialState = {
  id: ALL_PRODUCTS_CATEGORY_ID,
  order: 0,
  name: 'all-products-category',
}

type CategoriesProviderProps = {
  categories: Category[]
  activeCategory?: Category
  activeCategoryId?: string
  allProductsCategory: AllProductsCategory
}

type CategoriesContextType = {
  categories: Category[]
  categoriesMapById: Record<string, Category>
  activeCategory?: Category
  isAllProducts?: boolean
  allProductsCategory: AllProductsCategory
}

const initialState: CategoriesContextType = {
  categories: [],
  categoriesMapById: {},
  activeCategory: undefined,
  isAllProducts: undefined,
  allProductsCategory: allProductsCategoryInitialState,
}

export const CategoriesContext = createContext(initialState)
export const useCategories = () => useContext(CategoriesContext)

export const CategoriesProvider = (props: PropsWithChildren<CategoriesProviderProps>) => {
  const { categories, activeCategory, activeCategoryId, children, allProductsCategory } = props

  const memoValue = useDeepCompareMemo(() => {
    const categoriesMapById = keyBy(categories, 'id')
    let realActiveCategory = activeCategory

    if (realActiveCategory == null && isNotEmpty(activeCategoryId)) {
      realActiveCategory = categoriesMapById[activeCategoryId]
    }

    const isAllProducts = realActiveCategory == null

    return {
      categories,
      activeCategory: realActiveCategory,
      isAllProducts,
      allProductsCategory,
      categoriesMapById,
    }
  }, [categories, activeCategory, activeCategoryId, allProductsCategory])

  return <CategoriesContext.Provider value={memoValue}>{children}</CategoriesContext.Provider>
}
