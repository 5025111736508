/* eslint-disable @next/next/no-img-element */
/* eslint-disable jsx-a11y/alt-text */ // alt is inside the props
import type { ComponentProps } from 'react'
import { forwardRef, useEffect, useState } from 'react'

import env from '@beam-australia/react-env'
import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import logger from '@knauf-group/ct-shared-nextjs/logger'
import queryString from 'query-string'
import { useDeepCompareMemo } from 'use-deep-compare'

// see: https://experienceleague.adobe.com/en/docs/dynamic-media-developer-resources/image-serving-api/image-serving-api/http-protocol-reference/command-reference/c-command-reference
type AdobeParams = {
  align?: string
  anchor?: string
  bfc?: string
  bgc?: string
  bgColor?: string
  blendMode?: string
  cache?: string
  clipPath?: string
  clipXPath?: string
  color?: string
  crop?: string
  cropPathE?: string
  defaultImage?: string
  dpr?: string
  effect?: string
  effectMask?: string
  extend?: string
  fit?: string
  flip?: string
  fmt?: string
  hei?: string
  hide?: string
  icc?: string
  iccEmbed?: string
  id?: string
  imageSet?: string
  jpegSize?: string
  layer?: string
  locale?: string
  map?: string
  mask?: string
  maskUse?: string
  network?: string
  op_blur?: string
  op_brightness?: string
  op_colorbalance?: string
  op_colorize?: string
  op_contrast?: string
  op_grow?: string
  op_growMask?: string
  op_growMaskR?: string
  op_hue?: string
  op_invert?: string
  op_noise?: string
  op_saturation?: string
  op_sharpen?: string
  op_usm?: string
  op_usmR?: string
  opac?: string
  origin?: string
  pathAttr?: string
  pathEmbed?: string
  perspective?: string
  pos?: string
  printRes?: string
  pscan?: string
  qlt?: string
  quantize?: string
  rect?: string
  req?: string
  res?: string
  resMode?: string
  rgn?: string
  rotate?: string
  scale?: string
  scl?: string
  size?: string
  src?: string
  template?: string
  text?: string
  textAngle?: string
  textAttr?: string
  textFlowPath?: string
  textFlowXPath?: string
  textPath?: string
  textPs?: string
  type?: string
  wid?: string
  xmpEmbed?: string
}

type WidOrHei = { wid?: number; hei?: number }

type ForcedAdobeParams = Omit<AdobeParams, 'wid' | 'hei'> & WidOrHei

type KnaufImageProps = ComponentProps<'img'> & {
  damId?: string
  adobeParams: ForcedAdobeParams
} & QaHook

const alwaysAppliedAdobeParams = { fmt: 'webp', fit: 'wrap' } as const

const generateS7Src = (src: string, adobeParams: ForcedAdobeParams) => {
  const url = new URL(src)
  const searchParams = queryString.stringify(adobeParams)
  url.search = searchParams
  return url.toString()
}

const SUPPORTED_PIXEL_DENSITY_LEVELS = [2, 3]

const generateS7SrcSet = (src: string) => {
  const url = new URL(src)
  const params = queryString.parse(url.search)

  const srcSet = SUPPORTED_PIXEL_DENSITY_LEVELS.map((density) => {
    const newParams = {
      ...params,
      ...(params.hei && { hei: Number(params.hei) * density }),
      ...(params.wid && { wid: Number(params.wid) * density }),
    } // Update only 'hei' or 'wid' based on what's present
    url.search = queryString.stringify(newParams)
    return `${url.toString()} ${density}x`
  }).join(', ')

  return srcSet
}

export const KnaufImage = forwardRef<HTMLImageElement, KnaufImageProps>((props, ref) => {
  const { damId, dataCy, adobeParams, ...restProps } = props

  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [props.src])

  const { src, srcSet } = useDeepCompareMemo(() => {
    if (props.src == null) return { src: undefined, srcSet: undefined }

    const isSrcFromS7 = props.src?.startsWith('https://s7g10.scene7.com')
    if (!isSrcFromS7) {
      logger.warn('Image src is not from S7')
      return { src: props.src, srcSet: undefined }
    }

    const s7Src = generateS7Src(props.src, { ...alwaysAppliedAdobeParams, ...adobeParams })
    return { src: s7Src, srcSet: generateS7SrcSet(s7Src) }
  }, [props.src, adobeParams])

  return (
    <img
      {...restProps}
      ref={ref}
      dam-id={damId}
      data-cy={dataCy}
      src={error ? env('BROKEN_IMAGE_ICON_URL') : src}
      onError={() => setError(true)}
      srcSet={srcSet}
    />
  )
})
