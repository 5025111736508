import type {
  CategoriesDestinationRoute,
  DestinationRoute,
  ProductDestinationRoute,
} from '@/constants/routing'
import { useLocalePathGenerator } from '@/utils/frontend/usePathGenerator'
import { useT } from '@/utils/frontend/useT'

type BreadcrumbsProps =
  | {
      page: Extract<DestinationRoute, typeof CategoriesDestinationRoute>
      categoryName: string
      categorySlug?: never
      productName?: never
    }
  | {
      page: Extract<DestinationRoute, typeof ProductDestinationRoute>
      categoryName: string
      categorySlug: string
      productName: string
    }

export const useBreadcrumbsLinks = (props: BreadcrumbsProps) => {
  const { page, categoryName, categorySlug, productName } = props
  const { t } = useT()
  const { getCategoryPagePath } = useLocalePathGenerator()

  const breadcrumbsLinks = [
    { href: getCategoryPagePath() as string, label: t('common.breadcrumbs.products') },
  ]

  if (page === 'category') {
    breadcrumbsLinks.push({ href: '#', label: categoryName })
  } else {
    // its a product page
    breadcrumbsLinks.push(
      { href: getCategoryPagePath({ categorySlug }), label: categoryName },
      { href: '#', label: productName },
    )
  }

  return breadcrumbsLinks
}
