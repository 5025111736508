import logger from '@knauf-group/ct-shared-nextjs/logger'

export const extractIdFromSlug = (slug: string) => {
  try {
    const slugSegments = slug.split('-')
    return slugSegments.at(-1)
  } catch (error) {
    logger.error(`getIdFromSlug > Invalid slug: '${slug}'`, error)
    return undefined
  }
}

export const extractProductId = (asPath: string) => {
  const slugs = asPath.split('/')
  const productSlug = slugs.at(-1)?.split('?')[0]
  const productId = extractIdFromSlug(productSlug!)
  return productId
}
