import { useEffect, useRef } from 'react'

import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import clone from 'lodash/clone'

import type {
  ProductCatalogDataLayerProperties,
  ProductDetailDataLayerProperties,
} from './propertiesHelpers'
import { defaultValues, usePageChangeParameters } from './propertiesHelpers'
import { useInitializeAnalytics } from './useInitializeAnalytics'

const thresholds = [25, 50, 75, 90]
const initialScrollTrackingThresholdsStatus: { [threshold: number]: boolean } =
  thresholds.reduce((acc, percentage) => ({ ...acc, [percentage]: false }), {})

type ReachedThresholds = typeof initialScrollTrackingThresholdsStatus

const reportPercentages = (
  trackAnalytics: Function,
  scrollPercentage: number,
  trackedRef: ReachedThresholds,
  customProperties: object,
) => {
  // one single hook call might be triggered for a scroll from, e.g. 10% to 60% of the page
  // we want to report both 25% and 50% in this case, hence `filter` instead of `find`
  const scrollDepthPercentageInReports = thresholds.filter(
    (percentage) => scrollPercentage >= percentage && !trackedRef[percentage],
  )

  scrollDepthPercentageInReports.forEach((percentage) => {
    const percentageToReport = percentage === 90 ? 100 : percentage
    trackedRef[percentage] = true

    const eventName = `scroll_tracking_${percentageToReport}%`

    trackAnalytics(eventName, {
      ...defaultValues,
      ...customProperties,
      event_specification: eventName,
    })
  })
}

export const useTrackScrollPosition = (
  customProperties: ProductCatalogDataLayerProperties | ProductDetailDataLayerProperties,
) => {
  const { analytics, isReady } = useInitializeAnalytics()

  const trackedRef = useRef(clone(initialScrollTrackingThresholdsStatus))

  const { pathWithoutParams, pageProductType } = usePageChangeParameters(customProperties)

  useEffect(() => {
    if (!isReady) return

    trackedRef.current = clone(initialScrollTrackingThresholdsStatus)

    const scrollPercentage =
      ((window.scrollY + window.innerHeight) / document.documentElement.scrollHeight) * 100

    reportPercentages(analytics.track, scrollPercentage, trackedRef.current, customProperties)
  }, [pathWithoutParams, pageProductType, isReady])

  useScrollPosition(({ currPos, prevPos }) => {
    const allTracked = Object.values(trackedRef.current).every((tracked) => tracked)

    if (!isReady || allTracked) return

    // we don't take action when scrolled up
    if (-prevPos.y > -currPos.y) return

    const scrollPercentage =
      ((-currPos.y + window.innerHeight) / document.documentElement.scrollHeight) * 100

    reportPercentages(analytics.track, scrollPercentage, trackedRef.current, customProperties)
  })
}
