import { isNotEmpty } from './isEmpty'

type GenerateImageAltProps = {
  productName: string
  imageTitle?: string
}

const IMAGE_ALT_PREFIX = 'Knauf'

export const generateImageAlt = (props: GenerateImageAltProps) => {
  const { productName, imageTitle } = props

  let alt = `${IMAGE_ALT_PREFIX} - ${productName}`

  if (isNotEmpty(imageTitle)) {
    alt = `${alt} - ${imageTitle}`
  }

  return alt
}
