import type { ChangeEventHandler, PropsWithChildren } from 'react'
import { createContext, useContext, useState } from 'react'
import { useSearchBox } from 'react-instantsearch-core'

import { debounce } from 'lodash'
import { useDeepCompareMemo } from 'use-deep-compare'

type CategoriesContextType = {
  value: string
  onChange: ChangeEventHandler<HTMLInputElement>
  clear: () => void
}

const initialState: CategoriesContextType = {
  value: '',
  onChange: () => {},
  clear: () => {},
}

export const SearchBoxWithInternalStateContext = createContext(initialState)
export const useSearchBoxWithInternalState = () => useContext(SearchBoxWithInternalStateContext)

// let's debounce the query to avoid too many requests
const queryHook = debounce(function (query, refine) {
  refine(query)
}, 500)

export const SearchBoxWithInternalStateProvider = (props: PropsWithChildren<{}>) => {
  const { children } = props

  const { query, refine, clear: algoliaClear } = useSearchBox({ queryHook })
  const [inputValue, setInputValue] = useState(query)

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target

    const oldInputValue = inputValue
    setInputValue(value)

    const valueTrimmed = value.trim()

    if (oldInputValue !== valueTrimmed) {
      // do actual Algolia search with 2+ characters only after search term is trimmed
      refine(valueTrimmed.length >= 2 ? valueTrimmed : '')
    }
  }

  const clear = () => {
    setInputValue('')
    algoliaClear()
  }

  const memoValue = useDeepCompareMemo(
    () => ({
      value: inputValue,
      onChange,
      clear,
    }),
    [inputValue, onChange, clear],
  )

  return (
    <SearchBoxWithInternalStateContext.Provider value={memoValue}>
      {children}
    </SearchBoxWithInternalStateContext.Provider>
  )
}
