import { useSortBy } from 'react-instantsearch-core'
import { useRouter } from 'next/router'

import type { SortByItem } from 'instantsearch.js/es/connectors/sort-by/connectSortBy'

import {
  ALGOLIA_NAME_ASC_SORT_INDEX_SUFFIX,
  ALGOLIA_NAME_DESC_SORT_INDEX_SUFFIX,
} from '@/constants/algolia'
import { useT } from '@/utils/frontend/useT'
import { generateAlgoliaIndexName } from '@/utils/plain/generateAlgoliaIndexName'
import { splitLocale } from '@/utils/plain/splitLocale'

export const generateSortByItems = (locale: string): SortByItem[] => {
  const { country } = splitLocale(locale)

  // label is a t key for 'catalog.filters.sort-by'
  return [
    {
      label: 'recommended',
      value: generateAlgoliaIndexName({ country }),
    },
    {
      label: 'name-asc',
      value: generateAlgoliaIndexName({ country, sortBy: ALGOLIA_NAME_ASC_SORT_INDEX_SUFFIX }),
    },
    {
      label: 'name-desc',
      value: generateAlgoliaIndexName({ country, sortBy: ALGOLIA_NAME_DESC_SORT_INDEX_SUFFIX }),
    },
  ]
}

export const useNormalizedSortBy = () => {
  const { locale } = useRouter()
  const { t } = useT({ keyPrefix: 'catalog.filters.sort-by' })
  const items = generateSortByItems(locale)

  return {
    ...useSortBy({
      items: items.map((item) => ({
        // @ts-ignore
        label: t(item.label),
        value: item.value,
      })),
    }),
    originalItems: items,
  }
}
