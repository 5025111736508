import env from '@beam-australia/react-env'

import { isNotEmpty } from './isEmpty'

export const generateAlgoliaIndexName = ({
  country,
  sortBy,
}: {
  country: string
  sortBy?: string
}) => {
  let indexName = `${env('ALGOLIA_INDEX_PREFIX')}_${country.toLowerCase()}`

  if (isNotEmpty(sortBy)) {
    indexName = `${indexName}_${sortBy}`
  }

  return indexName
}
