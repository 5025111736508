import { useEffect } from 'react'

import { pickBy } from 'lodash'

import type { Product } from '@/types'
import { isNotEmpty } from '@/utils/plain/isEmpty'

import {
  defaultValues,
  getProductDetailProperties,
  type ProductCatalogDataLayerProperties,
  type ProductDetailDataLayerProperties,
  useCatalogCustomPropertiesFromAlgolia,
  usePageChangeParameters,
} from './propertiesHelpers'
import { useInitializeAnalytics } from './useInitializeAnalytics'

type PageProperties = ProductDetailDataLayerProperties | ProductCatalogDataLayerProperties

function usePageView(pageProperties: PageProperties) {
  const { analytics, isReady } = useInitializeAnalytics()

  const { pathWithoutParams, pageProductType } = usePageChangeParameters(pageProperties)

  const nonEmptyProperties = pickBy(pageProperties, isNotEmpty)

  useEffect(() => {
    if (isReady) {
      analytics.page({
        ...defaultValues,
        ...nonEmptyProperties,
      })
    }
  }, [isReady, pathWithoutParams, pageProductType]) // excluding nonEmptyProperties is at purpose, this are the only parameters that should re trigger the effect
}

export const useProductDetailPageView = (product: Product) => {
  const customProperties = getProductDetailProperties(product)
  usePageView(customProperties)
}

export const useCategoriesPageView = () => {
  const catalogCustomProperties = useCatalogCustomPropertiesFromAlgolia()

  usePageView(catalogCustomProperties)
}
