import type { QaHook } from '@knauf-group/ct-designs/utils/types'
import type { Theme } from '@mui/material'
import { Box, Typography } from '@mui/material'
import type { SxProps, SystemStyleObject } from '@mui/system'

import { useT } from '@/utils/frontend/useT'

type DIYLabelProps = {
  boxSx?: SxProps<Theme>
  typographySx?: SxProps<Theme>
} & QaHook

const styles = {
  DIYLabelContainer: {
    display: 'flex',
    bgcolor: 'grey.300',
    padding: '4px 8px',
  } as SystemStyleObject<Theme>,
}

export const DIYLabel = (props: DIYLabelProps) => {
  const { boxSx = [], typographySx = [], dataCy } = props

  const { t } = useT({ keyPrefix: 'common' })

  return (
    <Box
      sx={[styles.DIYLabelContainer, ...(Array.isArray(boxSx) ? boxSx : [boxSx])]}
      data-cy={`${dataCy}-DIYLabel`}
    >
      <Typography
        variant="overline"
        sx={[...(Array.isArray(typographySx) ? typographySx : [typographySx])]}
      >
        {t('DIY-label')}
      </Typography>
    </Box>
  )
}
